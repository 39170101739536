import {localisationService} from "../../services/localisation.service";
import React, {useEffect, useState} from "react";
import {getContract, getEtherscanAddress} from "../../helpers/contract.utils";
import {BigNumber, ethers} from "ethers";
import {MetaDataModel} from "../../models/metadata.model";
import {readMetaData} from "../../helpers/IPFS.utils";
import {addPadlock, deletePadlock, isPadlockAttached} from "../../helpers/db.utils";
import {PuffLoader} from "react-spinners";
import {addOwnerChecksum, checkOwnerChecksum} from "../../helpers/owner.db.utils";
import {globalService} from "../../services/global.service";


export default function ChangeMessage({ account = "", changePrice = "", onClose = () => {} }) {

    const [myNFT, setMyNFT] = useState<any[]>([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const [currentImageUrl, setCurrentImageUrl] = useState('');
    const [currentTokenId, setCurrentTokenId] = useState('');
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [currentIsPin, setCurrentIsPin] = useState(false);
    const [transationInProgress, setTransationInProgress] = useState(0);
    const [transationHashEtherscan, setTransationHashEtherscan] = useState("");
    const [isRefreshingNFT, setIsRefreshingNFT] = useState(false);


    useEffect(() => {

        refreshNFT(account);

        document.addEventListener("onRefresh", doRefresh );

        return () => {
            document.removeEventListener("onRefresh", doRefresh );

        }

    }, []);

    const doRefresh = ( e  : any  ) => {
        refreshNFT( account )
    }

    const refreshNFT = async (account: any) => {

        // Evite redondance
        if ( globalService.inRefreshNFT ) return ;
        globalService.inRefreshNFT = true ;

        setMyNFT(myNFT => []);

        const contract: any = getContract();

        let events: any = await contract.queryFilter('Transfer', 0);
        let myNFT: any[] = [];

        setIsRefreshingNFT(true);

        for (let event of events) {

            let obj: any = event.args;

            if (obj.to.toUpperCase() == account.toUpperCase()) {

                let id = obj.tokenId
                let ipfs = await contract.tokenURI(id);

                let meta: MetaDataModel = await readMetaData(ipfs);
                meta.tokenId = BigNumber.from(id).toString();
                meta.message = await contract.getMessage(id);


                if (!await checkOwnerChecksum(account, meta.tokenId)) {
                    // TODO Affichage du message
                    document.dispatchEvent(new CustomEvent("onPadlockMessage", {
                        detail: {message: meta.message, imageUrl: meta.imageUrl}
                    }));

                    await addOwnerChecksum(account, meta.tokenId);
                    console.log('')
                }

                myNFT.push(meta);

            }

            console.log(event);
        }

        setMyNFT(myNFT);
        doOnSelectMyNFT(0);
        setIsRefreshingNFT(false);
        globalService.inRefreshNFT = false ;

    }


    const doPin = async () => {


        if (await isPadlockAttached(currentTokenId)) {
            await deletePadlock(currentTokenId);
            setCurrentIsPin(false);
            document.dispatchEvent(new Event('onRefresh'));
            return;
        }


        addPadlock(currentTokenId, currentMessage, currentImageUrl)
        setCurrentIsPin(true);


        document.dispatchEvent(new Event('onRefresh'));
    }


    const doOnSelectMyNFT = async (index: number) => {


        if (myNFT.length === 0) return;

        setCurrentIndex(index);
        setCurrentMessage(myNFT[index].message);
        setCurrentImageUrl(myNFT[index].imageUrl);
        setCurrentTokenId(myNFT[index].tokenId);
        setCurrentIsPin(await isPadlockAttached(myNFT[index].tokenId))

        console.log(index);
    }

    const doChangeMessage = async () => {
        try {
            const {ethereum}: any = window;

            if (ethereum) {
                const nftContract: any =
                    getContract();

                console.log("Initialize payment");

                try {
                    let p: any = ethers.utils.parseEther(changePrice);
                    setTransationInProgress(1);
                    let nftTxn = await nftContract.changeMessage(currentMessage, {value: p});
                    await nftTxn.wait();

                    setTransationInProgress(2);
                    setTransationHashEtherscan(getEtherscanAddress() + `/tx/${nftTxn.hash}`);

                } catch (e: any) {
                    console.error("Error " + e.reason)

                }


            } else {
                console.log("Ethereum object does not exist");
            }

        } catch (err) {
            console.log(err);
        }
    }


    return (
        <>
            {/* PADLOCKS */}
            <div className="row">
                <div className="col-12 p-2 mt-4">
                    {!isRefreshingNFT ? (<>
                        {myNFT.map((element, index) => {
                            return (
                                <img
                                    className={currentIndex == index ? "border-gray border border-1 rounded-3 p-2 border-3 ms-2 me-2" : "ps-2 border-3 pb-2 pe-1"}
                                    src={element.imageUrl}
                                    onClick={() => doOnSelectMyNFT(index)} style={{height: "60px"}}/>
                            );
                        })}
                    </>) : (<> <PuffLoader color="#ffffff"/></>)}
                </div>
            </div>
            {/* IMAGE + TEXTAREA  */}
            <div className="row mt-3 p-2">

                <div className="col-12 text-center" style={{height: "150px"}}>
                    {currentIndex > -1 ? (
                        <>
                            <div className="p-2 h-100 w-100 text-white background-dark rounded-4">
                                            <textarea value={currentMessage}
                                                      onChange={e => setCurrentMessage(e.target.value)}
                                                      placeholder={localisationService.mintPlaceholder}
                                                      className="text-white font-normal border-0 h-100 w-100 background-dark rounded-4"></textarea>
                            </div>
                        </>
                    ) : (<><p className="mt-5 color-gray"> {localisationService.selectMessage}</p> </>)}
                </div>
            </div>
            {/* MESSAGE  */}
            <div className="font-small text-justify text-white m-1 mt-3 ">{localisationService.mintWarning} </div>
            {/* MINT COUNT + MINT BUTTON  */}

            {currentIndex > -1 ? (<>

                    <div className="row mt-2 mb-3">
                        <div className="col-5 text-center pt-3">
                            <div className="col-12 ">

                                <button type="button" onClick={doPin}
                                        className="btn font-bold font-normal p-3 text-white border-gray rounded-5 ">
                                    {!currentIsPin ? (<>
                                            <img className="ps-1 pe-2" src="/assets/pin.svg"/>{localisationService.pin}
                                        </>
                                    ) : (
                                        <>
                                            <img className="ps-1 pe-2"
                                                 src="/assets/pinned.svg"/>{localisationService.unpin}
                                        </>
                                    )}
                                </button>

                            </div>

                        </div>
                        <div className="col-7 text-end mt-3">
                            <button type="button" onClick={doChangeMessage}
                                    className="btn font-bold font-normal p-3 ps-4 pe-4 text-white bg-gray rounded-5 ">{localisationService.changeMessage} {changePrice} eth
                            </button>
                        </div>

                        <div className="row mt-2 mb-3">
                            <div className="col-1">

                                {transationInProgress == 1 ?
                                    (<>
                                        <PuffLoader color="#ffffff"/>
                                    </>) : (<></>)
                                }

                            </div>
                            <div className="col-11 p-3 font-small font-bold">

                                {transationInProgress > 0 ?
                                    (<>
                                        {transationInProgress === 1 ? (<> {localisationService.mintInProgressMessage}</>) : (<></>)}
                                        {transationInProgress === 2 ? (<> <a target="_blank"
                                                                             href={transationHashEtherscan}>{localisationService.mintFinishedMessage}</a></>) : (<></>)}
                                    </>) : (<></>)


                                }

                            </div>
                        </div>

                    </div>
                </>
            ) : (<></>)}
        </>
    )

}

