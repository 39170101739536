import React, {useEffect, useState} from 'react';
import Padlocks from "./padlocks";
import { logEvent } from "firebase/analytics";

// @ts-ignore
import Footer from "./footer";
import Mobile from "./devices/mobile";
import MobileHeader from "./devices/mobile.header";
import DesktopHeader from "./devices/desktop.header";
import Desktop from "./devices/desktop";
import {toast, Toaster} from "react-hot-toast";
import {globalService} from "../services/global.service";
import {localisationService} from "../services/localisation.service";
import {analytics} from "../firebase.config";




export default function Main() {

    const [isOpenMobile, setIsOpenMobile] = useState(false);
    const [isOpenDesktop, setIsDesktopOpen] = useState(false);


    const doOpenMobile = () => {
        setIsOpenMobile( !isOpenMobile );
    }

    const doOpenDesktop = () => {
        setIsDesktopOpen( true );
    }


    const doMintPadlock = () => {

        logEvent( analytics , "homepage_click_mint" )

       if ( isMobile ) {
           doOpenMobile()
       } else {
           doOpenDesktop();
       }
    }

    const doCloseDesktop = () => {
        setIsDesktopOpen( false );
    }



    const [ isMobile, setIsMobile] = useState(window.innerWidth <= 500);

    const doOnResize = () => {
        setIsMobile(window.innerWidth <= 900);
    }




    const doOnPadlockMessage = (  e : any ) => (
        toast.custom(
            <div className="bg-gray text-white p-3 rounded-5" style={{width : "400px" , backgroundColor : "#798783!important"}}>
                <div className="row">
                    <div className="col-3 text-center"><img style={{width:"70px"}} src={e.detail.imageUrl}/></div>
                    <div className="col-9 font-bold" style={{fontSize : "18px"}} >
                        <p style={{overflow: "hidden" , wordWrap: "break-word"}}>{e.detail.message}</p></div>
                </div>

            </div>)
    );


    useEffect(() => {

        localisationService.setLanguage("en");

        if ( !globalService.listenerAdded ) {
            document.addEventListener('onPadlockMessage', doOnPadlockMessage);
            document.addEventListener('resize', doOnResize);
            globalService.listenerAdded = true;
        }


    }, []);


    return (
        <>   <Toaster/><div className="overflow-hidden">

            { isMobile ? ( <>
                <MobileHeader onClick={ doOpenMobile }></MobileHeader>
                { isOpenMobile ? ( <> <Mobile></Mobile></> ) : ( <> <Padlocks onMintPadlock={ doMintPadlock}></Padlocks><Footer isDesktop={ !isMobile } ></Footer></> )}

            </> ) : (<>
                <DesktopHeader onClick={ doOpenDesktop }></DesktopHeader>
                <Desktop open={isOpenDesktop} onClick={doCloseDesktop} ></Desktop>
                <Padlocks onMintPadlock={ doMintPadlock}></Padlocks>
                <Footer isDesktop={ !isMobile } ></Footer>
            </>) }


        </div>
        </>

    );

}
