export function getG3R0Url() {
    return "https://g3r0.com";
}

export function getTermsUrl() {
    return "https://g3r0.com/padlocks-terms-of-use/";
}

export function getXUrl() {
    return "https://x.com/_g3r0";
}