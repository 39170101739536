import React from "react";
import Button from 'react-bootstrap/Button';
import {Col, Container, Row, Stack} from "react-bootstrap";
import {getTermsUrl} from "../helpers/utils";
import {localisationService} from "../services/localisation.service";

export default function Footer({isDesktop = false}) {
    return <>

        <div className="text-white text-center footer">
            {isDesktop ? (
                <>
                    <Stack className="opacity-50 " direction="horizontal" gap={3}>
                        <div className="ms-2 mt-3">Copyright 2024 - G3R0.com</div>
                        <div className="ms-2 mt-3">{process.env.REACT_APP_PADLOCKS_VERSION}</div>
                        <div className="ms-2 mt-3"><a target="_blank" href={getTermsUrl()}
                                                      className="text-white">{localisationService.terms}
                        </a>
                        </div>
                    </Stack>
                </>) : (<>
                <div className="ms-2 mt-3">Copyright 2024-G3R0.com {process.env.REACT_APP_PADLOCKS_VERSION} </div>

            </>)}
        </div>
    </>
}