import {ethers} from "ethers";
import contract from "../abi/padlocks-smartcontrat.json";
const abi: any = contract.abi;


export function getEtherscanAddress () {
    return process.env.REACT_APP_PADLOCKS_ETHERSCAN_ADDRESS;
}

export function getEtherscanSmartcontratAddress () {
    return process.env.REACT_APP_PADLOCKS_ETHERSCAN_ADDRESS + "address/" +
        process.env.REACT_APP_PADLOCKS_CONTRAT_ADDRESS;
}


export function getContract  ()  {

    let contratAddress = "" ;

    if ( process.env.REACT_APP_PADLOCKS_CONTRAT_ADDRESS) {
        contratAddress = process.env.REACT_APP_PADLOCKS_CONTRAT_ADDRESS;
    }

    if ( contratAddress != '') {

        const {ethereum}: any = window;
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        return new ethers.Contract(contratAddress, abi, signer);
    } else {
        throw "No contrat address !";
    }
}