import React from "react";
import {localisationService} from "../../services/localisation.service";

export default function Web3Connect ({ onConnect = async () => {} }) {

    const doConnect = () => {
        if ( onConnect )
            onConnect();
    }

    return (

        <>
            <div className="row">
                <div className="col-12 text-center pt-4 color-gray font-bold">
                    { localisationService.walletTitle  }
                </div>
                <div className="col-12 text-center p-5">

                    <button type="button" onClick={doConnect}
                            className="btn font-bold p-3 ps-4 pe-4 text-white bg-gray rounded-5 ">{localisationService.connect}</button>

                </div>


            </div>
        </>

    )

}