/**
 * MetaDataModel
 */
export class MetaDataModel {
    name = '';
    message = "" ;
    description = '';
    image = '';
    imageUrl = '';
    dna = '';
    data = '' ;
    tokenId = "";
}