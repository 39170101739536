import LocalizedStrings from 'react-localization';

const localisationService = new LocalizedStrings({
    "en": {
        "connect": "Connect Wallet",
        "disconnect": "Disconnect",
        "wallet" : "Wallet",
        "walletTitle" : "Connect your Metamask wallet",
        "mint": "Mint",
        "terms" : "Term of use",
        "mintMessage": "Whether it's a declaration of love, a sincere wish, a special commemoration, or even a simple greeting to the universe, these digital padlocks are the ideal canvas to express your feelings.",
        "mintWarning": "Messages are public; please adhere to the terms of use and avoid any offensive content. Additionally, messages should not serve a commercial purpose, and any incurred gas fees are not included. Failure to comply will result in the inability to post a message in the padlock.",
        "mintCount": "Mint Count",
        "manage": "Manage",
        "cancel": "Cancel",
        "changeMessage": "Change Msg",
        "mintPlaceholder": "Put your super message here...",
        "mintModalTitle": "Mint an NFT Padlock by G3R0",
        "pin": "Attach",
        "unpin": "Detach",
        "selectMessage": "Select a padlock above",
        "changeMessageModalTitle": "Change Message in NFT Padlock",
        "changeMessageModalSubtitle": "You can change the message in the Padlock",
        "clickPadlock": "Click on the padlock to select it",
        "mintInProgressMessage": "Minting an NFT Padlock in progress....",
        "mintFinishedMessage": "Minting of an NFT Padlock completed. Click for more information",
        "copyright": "Copyright 2024 G3R0.com"
    },
    "fr": {
        "connect": "Connexion",
        "disconnect": "Déconnexion",
        "wallet" : "Portefeuille",
        "walletTitle" : "Connexion de votre portefeuille Metamask",
        "mint": "Mint",
        "terms" : "Conditions d'utilisation",
        "mintMessage": "Qu'il s'agisse d'une déclaration d'amour, d'un vœu sincère, d'une commémoration spéciale ou même d'un simple salut à l'univers, ces cadenas numériques sont le canevas idéal pour exprimer vos sentiments.",
        "mintWarning": "Les messages sont publics ; veuillez respecter les conditions d'utilisation et éviter tout contenu offensant. De plus, les messages ne doivent pas avoir un but commercial, et les frais de gaz éventuels ne sont pas inclus. Le non-respect de ces règles entraînera l'incapacité de publier un message dans le cadenas.",
        "mintCount": "Nombre de NFT créés",
        "manage": "Gérer",
        "cancel": "Annuler",
        "changeMessage": "Changer le message",
        "mintPlaceholder": "Mettez votre super message ici...",
        "mintModalTitle": "Créer un NFT Padlock par G3R0",
        "pin": "Attacher",
        "unpin": "Détacher",
        "selectMessage": "Sélectionner un cadenas ci-dessus",
        "changeMessageModalTitle": "Changer le message dans le NFT Padlock",
        "changeMessageModalSubtitle": "Vous pouvez changer le message dans le Padlock",
        "clickPadlock": "Cliquez sur le cadenas pour le sélectionner",
        "mintInProgressMessage": "Création en cours d'un NFT Padlock....",
        "mintFinishedMessage": "Création d'un NFT Padlock terminée. Cliquez pour plus d'informations",
        "copyright": "Droits d'auteur 2024 G3R0.com"
    }
});


export {localisationService};