import React from "react";
import {getEtherscanAddress, getEtherscanSmartcontratAddress} from "../../helpers/contract.utils";
import {getG3R0Url, getTermsUrl, getXUrl} from "../../helpers/utils";

export default function MobileFooter() {

    return (
        <>
            {/* HR  */}
            <div className="row border-bottom border border-1 border-white opacity-10"/>
            {/* BUTTONS  */}
            <div className="row mt-4 mb-4">
                <div className="col-6 text-center">
                    <a target="_blank" href={ getEtherscanSmartcontratAddress() } className="btn text-white rounded-5 border-gray btn-footer"><img
                        className="me-2" src="assets/smartcontrat.svg" width="20px"/>Smartcontrat
                    </a>
                </div>
                <div className="col-6 text-center">
                    <a target="_blank" href={ getG3R0Url()} className="btn text-white rounded-5 border-gray btn-footer"><img
                        className="me-2" src="assets/earth.svg" width="20px"/>G3R0.com
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="col-6 text-center">
                    <a target="_blank" href={ getXUrl()} className="btn text-white rounded-5 border-gray btn-footer"><img
                        className="me-2" src="assets/xlogo.svg" width="20px"/>_g3r0
                    </a>

                </div>
                <div className="col-6  text-center mb-5">
                    <a target="_blank" href={ getTermsUrl()} className="btn text-white rounded-5 border-gray btn-footer"><img
                        className="me-2" src="assets/terms.svg" width="20px"/>Terms of use
                    </a>
                </div>

            </div>

        </>

    )
}