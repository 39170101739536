import React, {useEffect, useState} from 'react';
import './App.css';
import Main from "./components/main";


function App() {

    return (
    <>
        <Main></Main>
    </>
    )
}


export default App;
