import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import firebase from "firebase/compat";





        const firebaseConfig = {
            apiKey: process.env.REACT_APP_FB_AK,
            authDomain: process.env.REACT_APP_FB_AD,
            projectId: process.env.REACT_APP_FB_PI,
            storageBucket: process.env.REACT_APP_FB_SB,
            messagingSenderId: process.env.REACT_APP_FB_MS,
            appId: process.env.REACT_APP_FB_ID,
            measurementId: process.env.REACT_APP_FB_MI,
        };



const app = initializeApp(firebaseConfig);
export const analytics : any = getAnalytics(app);
export const db = getFirestore(app);