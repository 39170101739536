import {deleteDoc, doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../firebase.config";
import {PadlockModel} from "../models/padlock.model";


async function isPadlockAttached ( tokenId = "" ) {

    const docRef = doc(db, "padlocks", tokenId);
    const docSnap = await getDoc(docRef);
    let pinned : boolean = docSnap.exists();
    return pinned;

}

async function deletePadlock ( tokenId = "" ) {

    try {
        const docRef = doc(db, "padlocks", tokenId);
        await deleteDoc( docRef  );
    } catch (e) {
        console.log('deletePadlock'  , e);

    }
}


async function addPadlock ( tokenId = "" , message = "" , imageUrl = "" ) {

    let p: PadlockModel = new PadlockModel();
    p.message = message;
    p.imageUrl = imageUrl;

    try {
        let o: any = {};

        Object.assign(o, p);

        await setDoc(doc(db, 'padlocks', tokenId), o);
    } catch (e) {
        console.log('addPadlock'  , e);

    }
}


export { isPadlockAttached , addPadlock , deletePadlock }