import {localisationService} from "../../services/localisation.service";
import React, {useState} from "react";
import {getContract, getEtherscanAddress} from "../../helpers/contract.utils";
import {ethers} from "ethers";
import {PuffLoader} from "react-spinners";
import {addOwnerChecksum} from "../../helpers/owner.db.utils";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebase.config";



export default function Mint({account = "" , mintPrice = "", totalSupply = 0, maxSupply = 0}) {

    const [currentMessage, setCurrentMessage] = useState("");
    const [transationInProgress, setTransationInProgress] = useState(0);
    const [transationHashEtherscan, setTransationHashEtherscan] = useState("");

    const doMint = async () => {
        try {
            const {ethereum}: any = window;




            setTransationInProgress( 0 );

            if (ethereum) {
                const nftContract: any =
                    getContract();

                console.log("Initialize payment");

                try {
                    let p = ethers.utils.parseEther(mintPrice);
                    let nftTxn = await nftContract.mint(currentMessage, {value: p});
                    setCurrentMessage("");
                    setTransationInProgress( 1 );
                    await nftTxn.wait();



                    logEvent( analytics , "mint_success️" );


                    setTransationInProgress( 2 );
                    setTransationHashEtherscan( getEtherscanAddress() +  `/tx/${nftTxn.hash}` );
                    document.dispatchEvent(new Event('onRefresh'));

                } catch (e: any) {
                    console.error("Error " + e.reason);

                }


            } else {
                console.log("Ethereum object does not exist");
            }

        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {/* MESSAGE  */}
            <div className="font-small text-justify text-white m-1 mt-3 ">{localisationService.mintMessage}</div>
            {/* IMAGE + TEXTAREA  */}
            <div className="row mt-3">
                <div className="col-2 text-center">
                    <img className="mt-4  opacity-25" src="assets/padlock-mint.svg" width="70px"/>
                </div>
                <div className="col-10" style={{height: "150px"}}>
                    <div className="text-start h-100 w-100 text-white background-dark rounded-4">
                                            <textarea onChange={e => setCurrentMessage(e.target.value)}
                                                      placeholder={localisationService.mintPlaceholder}
                                                      className="text-white font-normal border-0 h-100 w-100  background-dark rounded-4 p-2"></textarea>
                    </div>
                </div>
            </div>
            {/* MESSAGE  */}
            <div className="font-small text-justify text-white m-1 mt-3 ">{localisationService.mintWarning} </div>
            {/* MINT COUNT + MINT BUTTON  */}
            <div className="row mt-2 mb-3">
                <div className="col-4 text-center pt-3">
                    <div className="col-12 m-1">{localisationService.mintCount}</div>
                    <div className="col-12 ">
                              <span
                                  className="p-1  ps-2 pe-2 font-normal border border-1 rounded-5 font-bold opacity-50">{totalSupply}/{maxSupply}</span>
                    </div>

                </div>
                <div className="col-8 text-end mt-3">


                    <button type="button" onClick={doMint}
                            className="btn font-bold font-normal p-3 ps-4 pe-4 text-white bg-gray rounded-5 ">{localisationService.mint} {mintPrice} eth
                    </button>


                </div>


                <div className="row mt-2 mb-3">
                    <div className="col-1">

                        { transationInProgress == 1 ?
                            ( <>
                                <PuffLoader color="#ffffff" />
                            </> ) : ( <></>)
                        }

                    </div>
                    <div className="col-11 p-3 font-small font-bold">

                        { transationInProgress > 0 ?
                            ( <>
                                { transationInProgress === 1 ? (<> {localisationService.mintInProgressMessage}</> ) : (<></>) }
                                { transationInProgress === 2 ? (<> <a target="_blank" href={transationHashEtherscan}>{localisationService.mintFinishedMessage}</a></> ) : (<></>) }
                            </> ) : ( <></>)


                        }

                    </div>
                </div>
            </div>

        </>
    )

}

