import {localisationService} from "../../services/localisation.service";
import React, {useEffect, useState} from "react";
import packageJson from "../../../package.json";
import ChangeMessage from "./change.message";
import MobileFooter from "./mobile.footer";
import Web3Connect from "./web3.connect";
import {getContract} from "../../helpers/contract.utils";
import {MetaDataModel} from "../../models/metadata.model";
import {readMetaData} from "../../helpers/IPFS.utils";
import {BigNumber, ethers} from "ethers";
import Mint from "./mint";
import {globalService} from "../../services/global.service";
import Commun from "./commun";


export default function Mobile( ) {

    return (
        <>
            <div className="p-2 w-100 vh-100 bg-black text-white overflow-y-scroll">
                <Commun></Commun>
                <MobileFooter></MobileFooter>
            </div>
        </>
    )
};

