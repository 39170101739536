import {MetaDataModel} from "../models/metadata.model";

/**
 * ipfsToIpfsWeb2
 * @param ipfs
 */
function ipfsToHttp ( ipfs : string ) {
    return ipfs.replace('ipfs://' , 'https://olive-worthy-stork-258.mypinata.cloud/ipfs/')
}

/**
 * readMetaData
 * @param ipfs
 */
async function readMetaData ( ipfs : string ) {
    let ipfsHttp = ipfsToHttp ( ipfs );
    let metadata : MetaDataModel = new MetaDataModel() ;

    try {
        let ret = await fetch(ipfsHttp);
        let reponse = await ret.json();
        Object.assign( metadata , reponse );

        metadata.imageUrl = ipfsToHttp( metadata.image );


    } catch (e) {
       console.error ('readIpfsHttp', e) ;
    }
    return metadata;
}




export  { readMetaData , ipfsToHttp}



