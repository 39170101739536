import {deleteDoc, doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../firebase.config";
import {PadlockModel} from "../models/padlock.model";
import md5 from "md5";


async function addOwnerChecksum ( account = '' , tokenId = '') {
    try {
        let checksum = md5(account).toString();
        await setDoc(doc(db, 'owners', tokenId), {checksum: checksum});
    } catch (e) {
        console.error( 'addOwnerChecksum' , e )
    }
 }

async function checkOwnerChecksum (  account = '' , tokenId = '' ) {
    try {
        const docRef = doc(db, "owners", tokenId);
        const docSnap = await getDoc(docRef);
        if (  docSnap.exists() ) {
            const data : any  = docSnap.data();
            let checksum = md5(account).toString();

            if ( data && data.checksum ) {
                return data.checksum ===  checksum;
            }
        }

    } catch (e) {
        console.error('checkOwnerChecksum'  , e);

    }
    return false ;
}


async function addPadlock ( tokenId = "" , message = "" , imageUrl = "" ) {

    let p: PadlockModel = new PadlockModel();
    p.message = message;
    p.imageUrl = imageUrl;

    try {
        let o: any = {};

        Object.assign(o, p);

        await setDoc(doc(db, 'padlocks', tokenId), o);
    } catch (e) {
        console.log('addPadlock'  , e);

    }
}


export { addOwnerChecksum , checkOwnerChecksum }