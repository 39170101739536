import React from "react";
import ReactModal from "react-modal";
import Commun from "./commun";


export default function Desktop({ open = false , onClick = () => {} }) {
    return (

        <>
            <ReactModal isOpen={open} className="mint-modal" overlayClassName="mint-modal-overlay">
                <div className="row pb-2">
                    <div className="col-10 text-start font-bold">
                        Padlocks
                    </div>
                    <div className="col-2 text-end">
                        <button type="button" onClick={onClick} className="btn font-bold p-1 ps-4 pe-4 text-white bg-gray rounded-5">X</button>
                    </div>
                </div>
                <div className="row border-bottom border border-1 border-white opacity-10"/>
                <Commun></Commun>

            </ReactModal>


</>

)

}