import React from "react";
import {localisationService} from "../../services/localisation.service";
import {getEtherscanAddress, getEtherscanSmartcontratAddress} from "../../helpers/contract.utils";
import {getG3R0Url, getTermsUrl, getXUrl} from "../../helpers/utils";

export default function DesktopHeader({
                                          onClick = () => {
                                          }
                                      }) {
    return (

        <>
        <div className="row p-2 header">
            <div className="col-2">
                <img className="ms-2" src="/assets/logo.svg" height="50px"/>
            </div>


        <div className="col-10 text-end">
            <a target="_blank" href={getEtherscanSmartcontratAddress()}
               className="btn text-white"><img
                className="me-2" src="assets/smartcontrat.svg" width="20px"/>Smartcontrat
            </a>

            <a target="_blank" href={getG3R0Url()} className="btn text-white"><img
                className="me-2" src="assets/earth.svg" width="20px"/>G3R0.com
            </a>

            <a target="_blank" href={getXUrl()} className="btn text-white"><img
                className="me-2" src="assets/xlogo.svg" width="20px"/>_g3r0
            </a>

            <button onClick={onClick} type="button" className="btn font-bold p-3 text-white bg-gray rounded-5">
                {localisationService.wallet}
            </button>

        </div>
        </div>

</>

)

}