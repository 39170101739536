
import React, { useEffect, useState} from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase.config";
import {PadlockModel} from "../models/padlock.model";
import {toast} from "react-hot-toast";
import {localisationService} from "../services/localisation.service";


export default function Padlocks({ onMintPadlock = () => {} }  ) {


    const [padlocks, setPadLocks]  = useState<any[]>([]);

    const fetchPost = async () => {


        let padlocks : any[] = [];

        await getDocs(collection(db, "padlocks"))
            .then((querySnapshot)=>{
                const newData : any  = querySnapshot.docs
                    .map((doc) => ({...doc.data(), minted : true, id:doc.id }));
                newData.minted = true ;
                Object.assign( padlocks , newData )
            })


        let maxCount = 9999 - padlocks.length;
        if ( maxCount > 50 ) maxCount = 50;

        for ( let i = 0 ; i < maxCount ; i++ ) {

            padlocks.push ( { imageUrl :'assets/padlock-mint-white.svg'  , minted : false } );
        }

        setPadLocks ( padlocks );
    }


    const doRefresh = () => {
        fetchPost();
    };



    const doClickMint = () => {

    }

    const doOnClickPadlock = ( message = "" , imageUrl = "" ) => {

        document.dispatchEvent(new CustomEvent("onPadlockMessage", {
            detail: {message, imageUrl}
        }));


    }


    const doClick = () => {

        onMintPadlock();


    }

    useEffect( ()=>{
        fetchPost();

        document.addEventListener("onRefresh", doRefresh )

        return () => {
            document.removeEventListener('onRefresh', doRefresh);
        }

    }, [])

    return <>

        <div className="workspace overflow-scroll">
            <div className="" style={{ backgroundImage: `url('assets/bg2.svg')` , backgroundSize : "300px" }}>

                        {
                        padlocks?.map(( p : PadlockModel , i)=>(
                            <div className="float-start mt-2" style={{ margin:'10px' , width : "150px"  , height : "200px" }}>
                                <div>
                                    { p.minted  ? ( <>

                                    <img onClick={ () => doOnClickPadlock(p.message , p.imageUrl)} alt="Padlock" style={{  width:'150px'}} src={ p.imageUrl }></img>
                                    </> ) : (<>
                                        <img className="opacity-50" alt="Padlock"
                                             onClick={doClick} style={{  width:'150px'}} src={ p.imageUrl }></img></> ) }
                              </div>
                                <div className="text-center" style={{width : "150px" , height: "40px"}}>
                                { p.minted ? ( <>
                                    <div style={{ marginTop: "-5px" , height : "60px" }} className="font-very-small rounded-3 p-1 overflow-hidden text-center bg-white" >{p.message}</div> </> ) : (<>

                                    </> )

                                    }
                                </div>
                             </div>

                                ))
                        }

                    <div style={{height : "2000px"}}></div>

            </div>
        </div>
    </>
}

