import {localisationService} from "../../services/localisation.service";
import React, {useEffect, useState} from "react";
import ChangeMessage from "./change.message";
import Web3Connect from "./web3.connect";
import {getContract} from "../../helpers/contract.utils";
import {MetaDataModel} from "../../models/metadata.model";
import {readMetaData} from "../../helpers/IPFS.utils";
import {BigNumber, ethers} from "ethers";
import Mint from "./mint";
import {globalService} from "../../services/global.service";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebase.config";


export default function Commun( ) {

    const [totalSupply, setTotalSupply] = useState(0);
    const [maxSupply, setMaxSupply] = useState(0);

    const [myNFT, setMyNFT] = useState<any[]>([]);
    const [firstNftImage, setFirstNftImage] = useState("");
    const [currentAccount, setCurrentAccount] = useState("");
    const [shortAccount, setShortAccount] = useState("");
    const [mintPrice, setMintPrice] = useState("");
    const [changePrice, setChangePrice] = useState("");
    const [currentPage, setCurrentPage] = useState(0);

    const doDisconnect = () => {
        globalService.connected = false ;
        setCurrentAccount("");

    }


    const refreshNFT = async (eth: any, account: any) => {

        setMyNFT(myNFT => []);

        const contract: any = getContract();

        let events: any = await contract.queryFilter('Transfer', 0);
        let myNFT: any[] = [];

        for (let event of events) {

            let obj: any = event.args;

            if (obj.to.toUpperCase() == account.toUpperCase()) {

                let id = obj.tokenId
                let ipfs = await contract.tokenURI(id);

                let meta: MetaDataModel = await readMetaData(ipfs);
                meta.tokenId = BigNumber.from(id).toString();
                meta.message = await contract.getMessage(id);


                myNFT.push(meta);

            }

            console.log(event);
        }

        setMyNFT(myNFT);
        setFirstNftImage(myNFT[0].imageUrl);


    }


    const disconnectWallet = async () => {
        setCurrentAccount("");
        setShortAccount("");
        setMyNFT(myNFT => []);
    }

    useEffect(() => {


        if ( globalService.connected )
            connectWallet();
    }, []);

    const connectWallet = async () => {
        const {ethereum}: any = window;


        logEvent( analytics , "connecting_wallet" )

        if (!ethereum) {
            alert("Please install Metamask!");
        }

        try {
            const accounts = await ethereum.request({method: 'eth_requestAccounts'});
            let account = accounts[0]
            console.log("Found an account! Address: ",);
            await setAccount(accounts[0]);
            await refreshNFT(ethereum, accounts[0]);
            globalService.connected = true ;


        } catch (err) {
            console.log(err)
        }


    }



    const setAccount = async (account: string) => {
        const {ethereum}: any = window;

        setCurrentAccount(account);
        let s = account.slice(0, 6) + '..' + account.slice(-2, -1);
        setShortAccount(s);


        const contract: any = getContract();

        try {
            const maxSupply: any = await contract.getMaxSupply();
            const totalSupply: any = await contract.totalSupply();
            const isSaleActive: any = await contract.getIsSaleActive();
            const mintPrice: any = await contract.getPricePerToken();
            const changePrice: any = await contract.getPriceForMessageChange();


            setMaxSupply(maxSupply.toNumber());
            setTotalSupply(totalSupply.toNumber());
            //setIsSaleActive(isSaleActive);
            setMintPrice( ethers.utils.formatUnits ( mintPrice , "ether") );
            setChangePrice( ethers.utils.formatUnits ( changePrice , "ether") );


        } catch (e) {
            console.log(e);
        }


        //setMaxSupply( m );
        //setTotalSupply( t );

        console.log("Initialize payment");

    }

    return (
        <>


                    {!currentAccount ? (
                    <>
                        <Web3Connect onConnect={connectWallet}></Web3Connect>
                    </>) : (<>
                    {/* ACCOUNT + DECONNECTION BUTTON  */}
                    <div className="row">
                        <div className="col-7 mt-3  d-flex align-content-center" style={{cursor:"pointer"}}>
                        <span className="font-bold m-3 font-normal"><img className="me-2 mb-1" src="assets/wallet.svg"
                                                                         height="20px"/>
                            {shortAccount}
                        </span>
                        </div>
                        <div className="col-5 text-end p-3">

                            <button type="button" onClick={doDisconnect}
                                    className="btn font-bold p-3 ps-4 pe-4 text-white bg-gray rounded-5 ">{localisationService.disconnect}</button>

                        </div>
                    </div>
                    {/* TABS  */}
                    <div className="row mt-4 ps-3 pe-3" style={{cursor:"pointer"}}>
                        <div onClick={() => setCurrentPage(0)}
                             className={currentPage == 0 ? "col-6 tab-selected text-center" : "col-6 tab-unselected text-center"}>
                         <span className="font-bold m-3 font-big "><img className="me-2 mb-1" src="/assets/mint.svg"
                                                                         height="30px"/>
                             {localisationService.mint}
                        </span>
                        </div>
                        <div onClick={() => setCurrentPage(1)}
                             className={currentPage == 0 ? "col-6 tab-unselected text-center" : "col-6 tab-selected text-center"}>
                            <div className={firstNftImage != '' ? 'opacity-100' : 'opacity-25'}>
                           <span className="font-bold m-3 font-big w-100">
                                <img src={firstNftImage !== '' ? firstNftImage : '/assets/padlock.svg'}
                                     style={{height: "30px" , marginRight : "-10px"}}/>
                                <span className="bg-gray  text-center rounded-5" style={{
                                }}>  {myNFT.length}  </span>
                                <span className="m-2 font-big">{localisationService.manage}</span>
                            </span>
                            </div>
                        </div>
                    </div>
                    {currentPage == 0 ? (
                        <>
                            <Mint mintPrice={mintPrice} totalSupply={totalSupply} maxSupply={maxSupply}></Mint></>
                    ) : (<>
                            <ChangeMessage changePrice={changePrice} account={currentAccount}></ChangeMessage></>
                    )}
                </>)}

        </>
    )
};

