import React from "react";

export default function MobileHeader ({ onClick =  () => {} } ) {
    return (

        <>
            {/* LOGO  */}
            <div className="row header">
                <div className="col-6">
                    <img className="mt-4 ms-2" src="/assets/logo.svg" height="50px" />
                </div>
                <div className="col-6 text-end p-3">
                    <button onClick={onClick} type="button" className="btn font-bold p-3 text-white bg-gray rounded-5"><img
                        src="/assets/hamburger.svg"/></button>

                </div>
            </div>

        </>

    )

}